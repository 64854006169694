import React from "react";
import styled from "styled-components";
import Button from "./Button";
import PText from "./PText";
import { DiGithubBadge } from "react-icons/di";
import { AiFillLinkedin } from "react-icons/ai";
import { AiOutlineMail } from "react-icons/ai";
const ContactBannerStyles = styled.div`
  padding: 5rem 0;
  .contactBanner__wrapper {
    background-color: #2f2626;
    border-radius: 12px;
    padding: 5rem 0rem;
    text-align: center;
  }
  .contactBanner__heading {
    font-size: 4rem;
    margin-bottom: 2rem;
    color: white;
  }

  .linkedin-icon {
    color: #0a66c2; // Add this style to set the color
  }

  .github {
    color: #ff9900;
  }

  .email {
    color: white;
  }

  @media only screen and (max-width: 768px) {
    .contactBanner__heading {
      font-size: 2.8rem;
    }
  }
`;

const handleEmailClick = () => {
  const email = "adrianmora828@gmail.com";
  // Creates a temporary textarea to copy the email to the clipboard
  const textarea = document.createElement("textarea");
  textarea.value = email;

  textarea.style.position = "fixed";
  textarea.style.opacity = 0;

  document.body.appendChild(textarea);

  // Focuses and select the text inside the textarea
  textarea.focus();
  textarea.select();

  try {
    // Attempts to copy the selected text to the clipboard
    document.execCommand("copy");
    alert(`Email copied to clipboard: ${email}`);
  } catch (err) {
    console.error("Unable to copy email to clipboard", err);
  } finally {
    // Removes the temporary textarea
    document.body.removeChild(textarea);
  }
};

export default function ContactBanner() {
  return (
    <ContactBannerStyles>
      <div className="container">
        <div className="contactBanner__wrapper">
          <h3 className="contactBanner__heading">Info/Contact Me</h3>
          <h2>
            <a href="https://github.com/adrianarturomora" target="_blank">
              <DiGithubBadge className="github" size={80} />
            </a>
            <a
              href="https://www.linkedin.com/in/adrianarturomora/"
              target="_blank"
            >
              <AiFillLinkedin className="linkedin-icon" size={73} />
            </a>
            <a onClick={handleEmailClick} style={{ cursor: "pointer" }}>
              <AiOutlineMail className="email" size={73} />
            </a>
          </h2>
        </div>
      </div>
    </ContactBannerStyles>
  );
}
