import React from "react";
import PText from "../components/PText";
import Button from "../components/Button";
import AboutImg from "../assets/images/ME.png";
import AboutInfoItem from "../components/AboutInfoItem";
import ContactBanner from "../components/ContactBanner";
import styled from "styled-components";
import ResumePDF from "../PDF/resume-3.pdf";
import "./About.css";

const AboutPageStyles = styled.div`
  padding: 5rem 0 10rem 0;

  .top-section {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2rem;
  }
  .left {
    flex: 3;
  }
  .right {
    flex: 2;
    img {
      width: 100%;
      max-width: 100%; // Ensure the image doesn't exceed its natural size
      object-fit: cover; // Maintain aspect ratio and cover the container
      border: 2px solid #333;
      border-radius: 5px; // Add border-radius
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.7); // Add box-shadow
    }
  }
  .about__subheading {
    font-size: 2.2rem;
    margin-bottom: 2rem;
    span {
      background-color: var(--deep-dark);
      padding: 0.5rem;
      border-radius: 8px;
    }
  }
  .about__heading {
    font-size: 3rem;
    margin-bottom: 3rem;
  }
  .about__info {
    margin-bottom: 4rem;
    .para {
      max-width: 100%;
    }
  }
  .right {
    img {
      border: 2px solid #333;
    }
  }
  .about__info__items {
    margin-top: 15rem;
  }
  .about__info__item {
    margin-bottom: 10rem;
  }
  .about__info__heading {
    font-size: 3.6rem;
    text-transform: uppercase;
  }
  @media only screen and (max-width: 768px) {
    padding: 10rem 0;
    .top-section {
      flex-direction: column;
      gap: 5rem;
    }
    .about__subheading {
      font-size: 1.8rem;
    }
    .about__heading {
      font-size: 2.8rem;
    }
    .about__info__heading {
      font-size: 3rem;
    }
    .right {
      img {
        max-width: 100%;
      }
    }

    body {
      overflow-x: hidden; // Prevent horizontal scrolling on the entire page
    }

    .container {
      overflow-x: hidden; // Prevent horizontal scrolling within the container
    }
  }
`;

function About() {
  const handleDownloadCV = async () => {
    try {
      const response = await fetch(ResumePDF); // Use the imported PDF reference
      const blob = await response.blob();

      const link = document.createElement("a");
      const blobURL = window.URL.createObjectURL(blob);

      link.href = blobURL;
      link.download = "resume-3.pdf";

      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
      window.URL.revokeObjectURL(blobURL);
    } catch (error) {
      console.error("Error downloading CV:", error);
    }
  };

  return (
    <AboutPageStyles>
      <div className="container">
        <div className="top-section">
          <div className="left">
            <p className="about__subheading">
              Hi, I am<span></span>
            </p>
            <h2 className="about__heading">Adrian Arturo Mora</h2>
            <div className="about__info">
              <PText>
                I am a from Asheville, North Carolina and I am currently
                enrolled at The University of North Carolina at Charlotte for
                computer science.
                <br /> <br />
                I started coding my sophomore year of college while I was trying
                figure out what career path I wanted to pursue. I began making
                some small projects using HTML, CSS, and Javascript and I
                realized that pursuing a computer science degree and getting
                into the technology space was for me.
                <br />
                <br />
                Everyday I strive to show the greatest part of who I am. I enjoy
                learning new things and meeting new people. On my free time, I
                will most likely be working out, creating animations, or hanging
                out with my friends.
              </PText>
            </div>
            <a
              href="https://drive.google.com/file/d/1oNmW1yg7NoyTDfz_RBCfgsg9uOPXoGQs/view?usp=sharing"
              target="_blank"
              rel="noopener noreferrer"
            >
              <button className="cv-button">
                                          Resume                        
              </button>
            </a>
          </div>
          <div className="right">
            <img src={AboutImg} alt="me" />
          </div>
        </div>
        <div className="about__info__items">
          <div className="about__info__item">
            <h1 className="about__info__heading">Education</h1>

            <AboutInfoItem
              title="Associates of Arts"
              items={["Asheville Buncombe Technical Community College"]}
            />
            <AboutInfoItem
              title="Bachelors of Science"
              items={["University of North Carolina at Charlotte"]}
            />
          </div>
          <div className="about__info__item">
            <h1 className="about__info__heading">My Skills</h1>

            <AboutInfoItem
              title="FrontEnd"
              items={["HTML", "CSS", "JavaScript"]}
            />
            <AboutInfoItem
              title="BackEnd"
              items={["Java", "Python", "C/C++"]}
            />
            <AboutInfoItem
              title="Databases"
              items={["AWS", "mySQL", "Azure"]}
            />
          </div>
          <div className="about__info__item">
            <h1 className="about__info__heading">Experiences</h1>

            <AboutInfoItem title="iCode" items={["Sep 2022 - Present"]} />
            <div className="about__info">
              <PText>
                • Successfully instructed computer science and programming
                concepts to students ranging from Kindergarten through 12th
                grade in a classroom setting, with class sizes of up to 15
                students.
                <br /> <br />
                • Developed and implemented a comprehensive computer science
                curriculum, enhancing student proficiency by 30% within a single
                academic year. Taught programming languages, including Java,
                Python, and JavaScript.
                <br />
              </PText>
            </div>
            <AboutInfoItem title="Target" items={["Aug 2021 - Present"]} />
            <div className="about__info">
              <PText>
                • Skillfully maintained, organized, and restocked shelves with a
                diverse product range, ensuring availability of over 500 unique
                items for customers in a high-demand Asheville retail
                environment.
                <br /> <br />
                • Perfected time management skills by successfully handling a
                daily workload of stocking shelves and preparing over 50 online
                orders, consistently meeting operational expectations.
                <br />
              </PText>
            </div>
          </div>
        </div>
      </div>
      <ContactBanner />
    </AboutPageStyles>
  );
}

export default About;
