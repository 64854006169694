import React from "react";
import "./Home.css";
import AboutImg from "../assets/images/PERUCLIMB.png";
import AboutImg2 from "../assets/images/HOMIES.png";
import AboutImg3 from "../assets/images/FOOD.png";
import AboutImg4 from "../assets/images/PERUFLAG.png";
import AboutImg5 from "../assets/images/COSTARICAFLAG.png";
import AboutImg6 from "../assets/images/CRFOOD.png";
function Home() {
  return (
    <div className="home">
      <h1>Welcome to my Portfolio Page!</h1>
      <section className="portfolio">
        <div className="portfolio-item">
          <div className="image-text-container">
            <img src={AboutImg} alt="me" />
            <p>This is me in Peru at the top of mount Humantay.</p>
          </div>
        </div>
        <div className="portfolio-item">
          <div className="image-text-container">
            <img src={AboutImg2} alt="me" />
            <p>These are my friends that I grew up with.</p>
          </div>
        </div>
        <div className="portfolio-item">
          <div className="image-text-container">
            <img src={AboutImg3} alt="me" />
            <p>
              These two dishes were made by my aunts in Peru. The dish on the
              left is Ceviche, which is the national dish of Peru. The one on
              the right is Olluquito, which is made by using potato, chicken,
              and rice.
            </p>
          </div>
        </div>
      </section>
      <section className="portfolio">
        <div className="portfolio-item">
          <div className="image-text-container">
            <img src={AboutImg4} alt="me" />
            <p>
              As you can assume, I am Peruvian, this is what the Peruvian flag
              looks like.
            </p>
          </div>
        </div>
        <div className="portfolio-item">
          <div className="image-text-container">
            <img src={AboutImg5} alt="me" />
            <p>
              ...but, I am also Costa Rican. The flag for Costa Rica can be
              found above.
            </p>
          </div>
        </div>
        <div className="portfolio-item">
          <div className="image-text-container">
            <img src={AboutImg6} alt="me" />
            <p>
              The food above is a Costa Rican dish. It is called Gallo Pinto.
              They usually make it with fried plantains, which is one of my
              favorites.
            </p>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Home;
