import Proj1 from "../assets/proj1.jpg";
import Proj2 from "../assets/proj2.jpg";
import Proj3 from "../assets/proj3.webp";
import Proj4 from "../assets/proj4.webp";
import Proj5 from "../assets/proj5.jpg";
import Proj6 from "../assets/proj6.png";
import CALC from "../assets/CALC.png";
import CLOCK from "../assets/CLOCK.png";
import MATH from "../assets/MATH2.png";
import LOGIN from "../assets/LOGIN.png";
import ECOM from "../assets/ECOM.png";
import Translator from "../assets/Translator.png";
import STOPWATCH from "../assets/STOPWATCH.png";
import TICTACTOE from "../assets/TICTACTOE.png";

export const ProjectList = [
  {
    name: "Power of Math Calculator",
    image: MATH,
    skills: "JavaScript,HTML,CSS",
    linkToPage: "https://dev.d1xswoaajpco3i.amplifyapp.com",
  },
  {
    name: "Digital Clock",
    image: CLOCK,
    skills: "React,Node.js,MongoDB",
    linkToPage: "http://mystatic4.s3-website-us-east-1.amazonaws.com",
  },
  {
    name: "Login Screen",
    image: LOGIN,
    skills: "React,Node.js,MongoDB,SpotifyAPI",
    linkToPage:
      "https://mystaticwebsite-aaa2023.s3.amazonaws.com/Adrian/loginpage2.html",
  },
  {
    name: "React Calculator",
    image: CALC,
    skills: "React,Node.js,MySQL,GraphQL",
    linkToPage: "https://main.d31nnq8es3k7yb.amplifyapp.com",
  },
  {
    name: "E-Commerce",
    image: ECOM,
    skills: "React,Node.js,MySQL,GraphQL",
    linkToPage: "http://ecom-bucket.s3-website-us-east-1.amazonaws.com",
  },
  {
    name: "Language Translator",
    image: Translator,
    skills: "HTML, CSS, JS",
    linkToPage: "http://adrian-translate.s3-website-us-east-1.amazonaws.com",
  },
  {
    name: "Stop Watch",
    image: STOPWATCH,
    skills: "HTML, CSS, JS",
    linkToPage: "http://adrian-stopwatch.s3-website-us-east-1.amazonaws.com",
  },
  
];
